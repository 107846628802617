


























import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import { getWorkflow } from '@/api/consoleApi/workflows'
import { getList } from '@/api/consoleApi/recipients'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'

@Component({
    name: 'WorkflowStatistics',
    components: {
        VsSectionHeader,
        VsContainer,
        VsTabsHeading,
    },
})
export default class extends Vue {
    loading = false
    workflow = null
    list = null
    $refs!: any

    get activeTab () {
        return this.$route.name
    }

    get tabs () {
        const tabs = [
            {
                label: 'Riepilogo',
                id: 'editWorkflow',
                to: {
                    name: 'editWorkflow',
                },
            },
            {
                label: 'Statistiche',
                id: 'workflowStatistics',
                to: {
                    name: 'workflowStatistics',
                },
            },
        ]

        return tabs
    }

    get workflowId () {
        return this.$route?.params?.workflowId || ''
    }

    beforeMount () {
        this.getWorkflow()
    }

    async getWorkflow () {
        try {
            const resp = await getWorkflow(this.workflowId)
            this.workflow = resp.data
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('workflow.list_id', { immediate: true, deep: true })
    async getList (listId: any) {
        if (!listId) return
        try {
            const resp = await getList(listId)
            this.list = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private editAutomationName () {
        return ''
    }

    private deleteAutomation () {
        return ''
    }
}
